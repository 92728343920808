@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.home-page {
    padding-bottom: 150px;
    
    .header-image {
      position: relative;
      width: 100%; // Full width
      img {
        width: 100%; 
        height: 500px; 
        object-fit: cover; 
      }
      h1 {
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
        color: white; 
        font-size: 3rem; 
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        text-align: center;
        font-family: "Roboto",sans-serif;
      }
    }
  
    .content-section {
      padding: 2rem;
      h2 {
        text-align: center;
        color: #fff;
        font-family: "Anta", sans-serif; 
      }
      
    }

    .meeting-time-section {
      text-align: center;
      margin: 20px 0;
  
      h2 {
        color: #ffffff;
        font-family: "Anta", sans-serif;
        text-decoration: underline;
      }
  
      p {
        color: #fff;
        font-family: "Roboto", sans-serif;
      }
    }
    .accomplishments {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .accomplishment-item {
        width: 30%;
        margin: 1rem;
        text-align: center;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
        }

        h3 {
          margin: 0.5rem 0;
          color: #1900fb !important ;
          font-family: anta;
        }
        p {
          margin: 0.5rem 0;
          color: #fff !important;
          font-family: "Roboto", sans-serif; 
        }
      }
    }
  }
  .join-now {
    text-align: center;
    margin: 30px 0; 
  
    h2 {
      margin: 0; 
      padding: 10px 0; 
  
      a {
        color: #fefefe; 
        font-weight: bold; 
        font-size: 1.5rem; 
        text-decoration: none; 
        transition: color 0.3s ease; 
  
        &:hover,
        &:focus {
          color: #0056b3; 
          text-decoration: underline; 
        }
      }
    }
    @media (max-width: 768px) {
      h2 {
        font-size: 1.2rem; // Adjusts the font size for mobile devices
      }
    }
  }

  @media (max-width: 768px) {
    .content-section .accomplishments .accomplishment-item {
      width: 100%;
    }
  
    
}
  

.arrow-container {
  width: 25px;
  /* cubic-bezier-easing = sine / mehr Beispiele: https://easings.net/ */
  animation: bounce 6s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  height: 20px;
  position: absolute;
  bottom: 40px;
}

@keyframes bounce {
50% {
    transform: translateY(-20px);
  }
}
