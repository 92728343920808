@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Anta&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    padding: 20px;
    padding-bottom: 150px;
  }
  
  .showcase-item {
    text-align: center;
    max-width: 600px; 
  
    img {
      width: 100%; 
      height: auto;
      border-radius: 8px; 
    }
  
    h2 {
      margin: 10px 0;
      color: #ffffff;
      font-family: "Anta", sans-serif;
      text-decoration: underline; 
    }
  
    p {
      color: #fff;
      font-family: "Roboto", sans-serif; 
    }
  }
  