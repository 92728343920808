@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import '../App.scss';

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 150px;
  background-color: $dark-gray; // Using the same background color from App styles

  h1 {
    text-align: center;
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
  }

  .about-section {
    text-align: center;
    max-width: 600px;
    margin: 20px 0; // Added margin for spacing between sections

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Added shadow for depth
    }

    h2 {
      margin: 10px 0;
      color: #ffffff;
      font-family: "Anta", sans-serif;
      text-decoration: underline;
      transition: color 0.3s ease; // Transition effect on hover
      &:hover {
        color: $logo-blue;
      }
    }

    p {
      color: #fff;
      font-family: "Roboto", sans-serif;
    }
  }

  .learn-section, .ctf-section, .team-section, .employment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  @media (max-width: 768px) {
    .about-container {
      padding: 10px;
    }

    .learn-section, .ctf-section, .team-section, .employment-section {
      img {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
